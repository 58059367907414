<!--
 * @Author: Link
 * @Date: 2020-12-15 09:15:19
 * @LastEditTime: 2021-06-11 11:12:19
-->
<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <div class="withdraw-record">
      <topbar title="提现记录" background="#e7141a" />
      <van-collapse v-model="activeName" class="list" accordion>
        <van-collapse-item
          :name="item.summaryMonth"
          class="header"
          v-for="(item, index) in list"
          :key="index"
        >
          <template #title>
            <div class="title">{{ item.summaryMonth }}</div>
            <div class="subtitle">提现 {{ item.summaryAmount }} 元</div>
          </template>
          <van-list
            class="content"
            v-model="month.loading"
            :finished="!month.isMore"
            finished-text="没有更多了"
            @load="loadMore"
          >
            <div
              class="item"
              v-for="(item, index) in month.list"
              :key="index"
              @click="$router.push('/withdraw/detail?id=' + item.id)"
            >
              <img class="icon" src="@/assets/icon/money.png" />
              <div class="mid">
                <div class="item-title">
                  余额提现-到{{
                    item.accountType == 1 ? '银行卡' : item.accountType == 2 ? '支付宝' : '微信零钱'
                  }}
                </div>
                <div class="date">{{ item.createTime | formatFullDate }}</div>
              </div>
              <div class="right">
                <div class="money">-{{ item.applyAmount }}</div>
                <div class="subtitle" :style="`color:${paymentStatus[item.paymentStatus].color}`">
                  {{ paymentStatus[item.paymentStatus].text }}
                </div>
              </div>
            </div>
          </van-list>
        </van-collapse-item>
      </van-collapse>
      <div class="noRecordText" v-if="!list.length">没有提现记录 ~~</div>
    </div>
  </van-pull-refresh>
</template>

<script>
export default {
  name: 'withdrawRecord',
  data() {
    return {
      activeName: -1,
      list: [],
      refreshing: false,
      loading: false,
      isMore: true,
      page: 1,
      month: {
        list: [],
        loading: false,
        isMore: false
      },
      paymentStatus: {
        0: { text: '待审核', color: '#999' },
        1: { text: '处理中', color: '#999' },
        2: { text: '提现成功', color: '#8BC34A' },
        3: { text: '提现失败', color: '#FF5252' },
        4: { text: '已拒绝', color: '#FF5252' }
      }
    }
  },
  created() {
    this.getMonth()
  },
  watch: {
    activeName(v) {
      if (v && v !== -1) {
        this.getDataByMonth()
      }
    }
  },
  methods: {
    onRefresh() {
      this.refreshing = true
      this.getMonth()
      this.activeName = -1
    },
    loadMore() {
      if (this.month.isMore) {
        this.getDataByMonth(this.month.page + 1)
      }
    },
    getMonth() {
      this.loading = true
      this.$http
        .get('boom-center-user-service/app/distribution/withdraw-records/summary')
        .then(res => {
          this.list = res
          // if (res.list.length) {
          //   res.list.map(item => this.month.push(item))
          //   this.month.page = page
          //   this.month.isMore = res.size * res.page < res.total
          // }
        })
        .finally(() => {
          this.loading = false
          this.refreshing = false
        })
    },
    getDataByMonth(page = 1) {
      if (page <= 0 || this.activeName === -1) return
      this.$http
        .get(
          `boom-center-user-service/app/distribution/withdraw-records?summaryMonth=${this.activeName}&page=${page}`
        )
        .then(res => {
          if (res.list.length) {
            let list = this.month.list
            if (page === 1) list = []
            this.month = {
              list: [...list, ...res.list],
              page: page,
              isMore: page * res.size < res.total
            }
          } else {
            this.month.isMore = false
          }
        })
        .catch(() => {
          this.month.isMore = false
        })
        .finally(() => {
          this.month.loading = false
        })
    }
  }
}
</script>

<style lang="less" scope>
.withdraw-record {
  min-height: 100vh;
  .list {
    .van-cell {
      background-color: #f9f9f9 !important;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
    .header {
      .title {
        font-size: 16px;
        color: #333333;
      }
      .subtitle {
        color: #999;
        font-size: 12px;
      }
    }
    .content {
      .item {
        display: flex;
        margin: 15px 0;
        margin-bottom: 20px;
        background: #ffffff;
        &:active {
          filter: brightness(95%);
        }
        .icon {
          width: 40px;
          height: 40px;
        }
        .mid {
          flex: 1;
          overflow: hidden;
          margin: 0 10px;
          .item-title {
            font-size: 15px;
            color: #333333;
          }
          .date {
            font-size: 10px;
            color: #999;
            margin-top: 5px;
          }
        }
        .right {
          text-align: right;
        }
      }
    }
  }
  .noRecordText {
    text-align: center;
    margin-top: 20px;
    color: #999;
  }
}
</style>
